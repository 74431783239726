import { useApolloClient } from '@apollo/client'
import { useFlag } from '@unleash/proxy-client-react'
import axios from 'axios'
import exifr from 'exifr'
import { getAuth } from 'firebase/auth'
import { chunk } from 'lodash'
import { useMatch, useParams } from 'react-router-dom'
import { v4 } from 'uuid'
import config, { baseDomain } from '../config'
import { BucketAlbumFragmentFragmentDoc, BucketFragmentFragmentDoc } from '../graphql/generated'
import logger from '../utils/logger'
import useGAEvent from '../views/auth/hooks/useGAEvent'
import useAlerts from '../views/buckets/hooks/useAlerts'
import useS3MulitpartUpload from '../views/buckets/hooks/useS3MulitpartUpload'
import useUploadsInProgress from '../views/buckets/hooks/useUploadsInProgress'
import { videoPlaceholder } from '../views/buckets/utils/placeholders'
import { MediaData } from '../views/buckets/utils/sharedTypes'

interface MediaServiceResponse {
  id: string
  originalFilename: string
  fileS3Key: string
  filename: string
  bucketId: string
  status: string
  failureReason?: string
  createdAt: string
  updatedAt: string
  externalId?: string
  userId: string
  description?: string
  title?: string
  uploadUrls: {
    id: string
    chunkSize: number
    urls: string[]
  }
  livePhoto?: {
    filename: string
    fileSize: number
    fileS3Key: string
    uploadUrls: {
      id: string
      chunkSize: number
      urls: string[]
    }
  }
  metadata: {
    dateTaken: string
    contentType: string
    fileSize: number
    width?: number
    height?: number
    originalFilename: string
    thumbnail?: {
      hash: string
    }
  }
}

interface ExifData {
  width?: number
  height?: number
  dateTaken: string
}

interface UploadPart {
  partNumber: number
  eTag: string
}

function useUploadImplementation(isEnabled: boolean) {
  const client = useApolloClient()
  const { createAlert } = useAlerts()
  const { beginTrackingProgress, updateStatus } = useUploadsInProgress()
  const { trackEvent } = useGAEvent()
  const params = useParams<{ bucketId: string; albumId: string }>()
  const path = useMatch('/bucket/:bucketId/album/:albumId')
  const bucketId = params.bucketId ?? path?.params.bucketId
  const albumId = params.albumId ?? path?.params.albumId
  const userId = getAuth().currentUser?.uid
  const { uploadSingle: oldUploadSingle } = useS3MulitpartUpload()

  // Extract EXIF data from image
  const extractExif = async (file: File): Promise<ExifData> => {
    const now = new Date().toISOString()

    // Skip EXIF extraction for unsupported formats
    const skipExif = ['gif', 'webp'].includes(file.type.split('/')[1] || '')
    if (skipExif) {
      return { dateTaken: now }
    }

    try {
      const exifData = await exifr.parse(file)
      const { ExifImageHeight, ExifImageWidth, CreateDate } = exifData || {}
      const dateTaken = CreateDate ? new Date(CreateDate).toISOString() : now

      if (ExifImageWidth && ExifImageHeight) {
        return { width: ExifImageWidth, height: ExifImageHeight, dateTaken }
      }

      // If EXIF doesn't have dimensions, load the image to get them
      const img = new Image()
      const imageUrl = URL.createObjectURL(file)

      try {
        const dimensions = await new Promise<{ width: number; height: number }>((resolve, reject) => {
          img.onload = () => {
            resolve({
              width: img.naturalWidth || 1,
              height: img.naturalHeight || 1
            })
          }
          img.onerror = reject
          img.src = imageUrl
        })

        return { ...dimensions, dateTaken }
      } finally {
        URL.revokeObjectURL(imageUrl)
      }
    } catch (error) {
      logger.error('Failed to extract EXIF:', error)
      return { dateTaken: now }
    }
  }

  const uploadFile = async (file: File) => {
    // console.log('Upload parameters:', { file, bucketId, userId, albumId })
    if (!file || !bucketId || !userId) {
      throw new Error('Missing required upload parameters')
    }

    const mediaId = v4()
    const mediaType = file.type
    const originalFilename = file.name
    const fileSize = file.size
    const isVideo = mediaType.toLowerCase().includes('video')
    const imageUrl = isVideo ? `${baseDomain}/${videoPlaceholder}` : URL.createObjectURL(file)
    const splitName = originalFilename.split('.')
    splitName.pop()
    const title = splitName.join('.')

    // Extract EXIF data for images
    const exifData = !isVideo ? await extractExif(file) : { dateTaken: new Date().toISOString() }

    // Start tracking progress
    const mediaData: MediaData = {
      albumId: albumId || undefined, // Ensure albumId is string or undefined
      title,
      mediaType,
      originalFilename,
      fileSize,
      imageUrl,
      createdAt: new Date().toISOString(),
      dateTaken: exifData.dateTaken,
      description: null,
      isBanned: false,
      isVideo,
      scheduledDeletionAt: null,
      userId,
      userTags: null,
      ...(exifData.width && exifData.height ? { width: exifData.width, height: exifData.height } : {})
    }
    beginTrackingProgress(bucketId, mediaId, mediaData)

    const token = await getAuth().currentUser?.getIdToken()
    if (!token) throw new Error('Not authenticated')

    try {
      const { data } = await axios.post<MediaServiceResponse>(
        `${config.api.mediaService}`,
        {
          bucketId,
          metadata: {
            dateTaken: exifData.dateTaken,
            contentType: mediaType,
            fileSize,
            ...(exifData.width ? { width: exifData.width } : {}),
            ...(exifData.height ? { height: exifData.height } : {}),
            originalFilename
          },
          albumId,
          title
        },
        {
          headers: {
            Authorization: `${token}`,
            'x-client-name': 'web',
            'x-client-version': process.env.REACT_APP_VERSION || '1.0.0',
            'x-request-id': mediaId
          }
        }
      )

      const { id, uploadUrls } = data
      const { chunkSize } = uploadUrls
      const numChunks = Math.ceil(fileSize / chunkSize)
      const parts: UploadPart[] = []
      let externalId: string | undefined

      const uploadPromises = Array.from({ length: numChunks }, async (_, i) => {
        const start = i * chunkSize
        const end = Math.min(start + chunkSize, fileSize)
        const fileChunk = file.slice(start, end)

        try {
          const response = await axios.put(uploadUrls.urls[i], fileChunk)
          externalId = response.data.externalId

          parts.push({
            partNumber: i + 1,
            eTag: response.headers.etag
          })

          updateStatus(mediaId, Math.round((end / fileSize) * 100))
        } catch (error) {
          logger.error(`Failed to upload chunk ${i + 1}:`, error)
          throw error
        }
      })

      await Promise.all(uploadPromises)

      // Step 3: Complete the upload
      await axios.put(
        `${config.api.mediaService}/${id}`,
        { s3UploadParts: parts },
        {
          headers: {
            Authorization: `${token}`,
            'x-client-name': 'web',
            'x-client-version': process.env.REACT_APP_VERSION || '1.0.0',
            'x-request-id': externalId ?? mediaId
          }
        }
      )
    } catch (error) {
      logger.error('Upload failed:', error)
      if (axios.isAxiosError(error)) {
        logger.error('Response:', error.response?.data)
        logger.error('Request:', error.config)
      }
      throw error
    }

    // Update Apollo cache
    client.cache.updateFragment(
      {
        id: `Bucket:${bucketId}`,
        fragment: BucketFragmentFragmentDoc
      },
      (current) => {
        if (!current) return null

        const totalMedia = current?.counters?.totalMedia ?? 0
        const totalMediaMinusTrash = current.counters?.totalMediaMinusTrash ?? 0
        const totalSize = current?.counters?.totalSize ?? 0

        return {
          ...current,
          counters: {
            ...current.counters,
            totalMedia: totalMedia + 1,
            totalMediaMinusTrash: totalMediaMinusTrash + 1,
            totalSize: totalSize + fileSize
          }
        }
      }
    )

    client.cache.updateFragment(
      {
        id: `BucketAlbum:${albumId}`,
        fragment: BucketAlbumFragmentFragmentDoc
      },
      (current) => {
        if (!current) return null

        const totalMedia = current?.counters?.totalMedia ?? 0

        return {
          ...current,
          counters: {
            ...current.counters,
            totalMedia: totalMedia + 1
          }
        }
      }
    )

    updateStatus(mediaId, 'COMPLETE')
    return mediaId
  }

  const uploadSingle = async (file: File) => {
    try {
      trackEvent('media_upload_event', {
        metric: 'media_upload_event',
        id: userId,
        bucketId,
        albumId,
        isMultiple: false,
        platform: 'WEB'
      })

      return await uploadFile(file)
    } catch (error) {
      logger.error('Upload failed:', error)
      createAlert('Upload failed. Please try again.')
      throw error
    }
  }

  const uploadMultiple = async (files: File[]) => {
    try {
      trackEvent('media_upload_event', {
        metric: 'media_upload_event',
        id: userId,
        bucketId,
        albumId,
        isMultiple: true,
        platform: 'WEB'
      })

      // Process files in batches of 5 to limit concurrent uploads
      const batchSize = 6
      // Create all batch promises upfront
      const batchPromises = Array.from({ length: Math.ceil(files.length / batchSize) }, (_, batchIndex) => {
        const start = batchIndex * batchSize
        const end = Math.min(start + batchSize, files.length)
        const batch = files.slice(start, end)
        return Promise.all(batch.map((file) => uploadFile(file)))
      })

      // Execute all batches sequentially using reduce
      const results = await batchPromises.reduce(async (accPromise, batchPromise) => {
        const acc = await accPromise
        const batchResults = await batchPromise
        return [...acc, ...batchResults]
      }, Promise.resolve([] as string[]))

      return results
    } catch (error) {
      logger.error('Batch upload failed:', error)
      createAlert('Upload failed. Please try again.')
      throw error
    }
  }

  // Original implementation referenced from useBucketMediaUpload.tsx
  const { batchUpload } = useS3MulitpartUpload()
  const uploadChunk = async (chunks: File[][], num: number, total: number) => {
    const batch = chunks.pop()
    if (!batch) return
    await batchUpload(batch, num, total)
    await uploadChunk(chunks, num + 1, total)
  }

  const oldUploadMultiple = (files: File[]) => {
    const chunks = chunk(files, 5)
    return uploadChunk(chunks, 1, chunks.length)
  }

  return isEnabled ? { uploadSingle, uploadMultiple } : { uploadSingle: oldUploadSingle, uploadMultiple: oldUploadMultiple }
}

export default function useMediaServiceUpload() {
  const isEnabled = useFlag('useMediaAPIWeb')
  return useUploadImplementation(isEnabled)
}
