import useMediaServiceUpload from '../../../hooks/useMediaServiceUpload'

export default function useBucketMediaUpload() {
  const { uploadSingle, uploadMultiple: uploadMultipleFiles } = useMediaServiceUpload()

  const uploadMultiple = (files: FileList) => {
    // Use the batch upload function from the hook
    uploadMultipleFiles(Array.from(files))
  }

  return { uploadMultiple, uploadSingle }
}
