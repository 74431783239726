import { useQuery } from '@apollo/client'
import { MoreHoriz } from '@mui/icons-material'
import { IconButton, Menu, MenuItem, Tooltip, styled } from '@mui/material'
import { useTheme } from '@mui/styles'
import { useFlag } from '@unleash/proxy-client-react'
import { getAuth } from 'firebase/auth'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { BucketShareType, BucketType, BucketUserRole, BucketsByUserIdDocument, PermissionPolicy } from '../../../../../graphql/generated'
import { activeAlbumIdVar, drawerVar, modalVar } from '../../../../../providers/apollo/cache'
import logger from '../../../../../utils/logger'
import useAlerts from '../../../hooks/useAlerts'
import useBucket from '../../../hooks/useBucket'
import useBucketPlan from '../../../hooks/useBucketPlan'
import useBucketUpdate from '../../../hooks/useBucketUpdate'
import useProfilePlan from '../../../hooks/useProfilePlan'
import useUserSubscription from '../../../hooks/useUserSubcription'

interface Props {
  permissions?: PermissionPolicy | null
  bucketId?: string
}

const DropdownButton = styled(IconButton)(({ theme }) => ({
  borderRadius: '4px',
  border: `1px solid ${theme.palette.secondary.main}`,
  height: '32px',
  width: '32px',
  '&:hover': {
    background: theme.palette.primary.highlight
  }
})) as typeof IconButton

export default function BucketMenu({ permissions, bucketId }: Readonly<Props>) {
  const theme = useTheme()
  const { createAlert } = useAlerts()
  const isBucketDeleteEnabled = useFlag('isBucketDeleteEnabled')
  const { bucket, bucketUser, currentUserIsOwner, isDefaultTitle } = useBucket()
  const { canCreateBucket, isSharingEnabled } = useProfilePlan()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const userId = getAuth().currentUser?.uid
  const { isSubscriptionActive } = useUserSubscription()
  const isPersonalBucket = bucket?.bucketType === BucketType.Mybucket
  const isGroupBucket = bucket?.bucketType === BucketType.Group
  const isViewer = bucketUser?.userRole === BucketUserRole.Viewer
  const canShareOwnBucket = isPersonalBucket && isSharingEnabled
  const canShareGroupBucket = isGroupBucket && !isViewer
  const canShareBucket = canShareGroupBucket || canShareOwnBucket
  const { isSharingPlan } = useBucketPlan()
  const { mutation } = useBucketUpdate()

  const shareTooltip = () => {
    if (isGroupBucket && isViewer) {
      return 'Your role for this bucket does not allow you to share buckets.'
    }
    if (isPersonalBucket && !isSharingEnabled) {
      return "Your plan doesn't support sharing media."
    }

    return ''
  }

  const disabled = !isSubscriptionActive
  const hideTooltip = !disabled

  useQuery(BucketsByUserIdDocument, {
    skip: !userId,
    variables: { userId: userId! },
    onError: (e) => {
      e.graphQLErrors.forEach((error) => {
        createAlert(error.message, 'error', error)
      })

      if (!e.graphQLErrors) {
        logger.error(e)
        createAlert('We are having trouble retrieving your buckets. Please try again later.')
      }
    }
  })

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const openCreateModal = (value: 'createBucket') => () => {
    modalVar(value)
    handleClose()
    drawerVar(null)
  }

  const openInviteModal = () => {
    if (!isGroupBucket && isSharingPlan && !isDefaultTitle) {
      mutation({
        variables: {
          bucketId: bucket?.id || '',
          data: { bucketType: BucketType.Group }
        },
        onCompleted: () => {
          modalVar('bucketInvite')
          createAlert('Successfully converted to group bucket.', 'success')
        }
      })
    } else {
      const modal = bucket?.bucketType === BucketType.Group ? 'bucketInvite' : 'bucketUpgrade'
      modalVar(modal)
    }
    drawerVar(null)
    handleClose()
  }

  const openShareModal = () => {
    modalVar('bucketSharingLinkCreate')
    drawerVar(null)

    // Since the modal already has the bucket id, pass in BUCKET as the albumId var so we know we're sharing a bucket, not an album
    activeAlbumIdVar(BucketShareType.Bucket)
    handleClose()
  }

  return (
    <>
      <DropdownButton onClick={handleOpen} data-testid="bucket-menu">
        <MoreHoriz fontSize="small" />
      </DropdownButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {permissions?.invitationCreate && !bucket?.isBanned && (
          <MenuItem onClick={openInviteModal} data-testid="bucket-menu-invite-members">
            Invite Members
          </MenuItem>
        )}
        {permissions?.userList && bucketId && !bucket?.isBanned && (
          <MenuItem component={Link} to={`/bucket/${bucketId}/manage`} data-testid="bucket-menu-settings">
            Bucket Settings
          </MenuItem>
        )}
        <MenuItem component={Link} to="/buckets" data-testid="bucket-menu-manage-buckets">
          View All Buckets
        </MenuItem>
        {canCreateBucket && (
          <Tooltip
            PopperProps={{
              disablePortal: hideTooltip
            }}
            disableFocusListener={hideTooltip}
            disableHoverListener={hideTooltip}
            disableTouchListener={hideTooltip}
            title="You need to have an active subscription to create a bucket."
          >
            <div>
              <MenuItem data-testid="bucket-menu-create-bucket" disabled={disabled} onClick={openCreateModal('createBucket')}>
                Create New Bucket
              </MenuItem>
            </div>
          </Tooltip>
        )}
        <Tooltip title={shareTooltip()}>
          <div>
            <MenuItem data-testid="bucket-menu-share-bucket" disabled={!canShareBucket} onClick={openShareModal}>
              Share Bucket
            </MenuItem>
          </div>
        </Tooltip>
        {isBucketDeleteEnabled && currentUserIsOwner && (
          <div>
            <MenuItem sx={{ color: theme.palette.customError[500] }} data-testid="bucket-menu-delete-bucket" onClick={() => modalVar('bucketDelete')}>
              Delete Bucket
            </MenuItem>
          </div>
        )}
      </Menu>
    </>
  )
}

BucketMenu.defaultProps = {
  permissions: null,
  bucketId: ''
}
