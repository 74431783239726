import { useApolloClient, useQuery } from '@apollo/client'
import { ArrowDropDown, FileCopyOutlined } from '@mui/icons-material'
import { Button, ButtonGroup, Menu, MenuItem, useTheme } from '@mui/material'
import _, { head, startCase } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { isIOS, isSafari } from 'react-device-detect'
import { useParams } from 'react-router-dom'
import { useCopyToClipboard } from 'react-use'
import { baseDomain } from '../../../../config'
import { BucketInvitationQueryDocument, BucketInvitationsDocument, BucketType, BucketUserRole } from '../../../../graphql/generated'
import { modalVar } from '../../../../providers/apollo/cache'
import { InvitationContext } from '../../InvitationContext'
import useAlerts from '../../hooks/useAlerts'
import useBucket from '../../hooks/useBucket'
import useBucketInvitationCreate from '../../hooks/useBucketInvitationCreate'
import useBucketPlan from '../../hooks/useBucketPlan'
import useBucketUpdate from '../../hooks/useBucketUpdate'

const items = [BucketUserRole.Contributor, BucketUserRole.Viewer, BucketUserRole.Admin]

const ITEM_HEIGHT = 48

export default function CopyLink() {
  const theme = useTheme()
  const { createAlert } = useAlerts()
  const { cache } = useApolloClient()
  const { bucket, isDefaultTitle } = useBucket()
  const { bucketId } = useParams<{ bucketId: string }>()
  const [userRole, setUserRole] = useState(BucketUserRole.Contributor)
  const { inviteCache, setInviteCache } = useContext(InvitationContext)

  const [copyState, copyToClipboard] = useCopyToClipboard()
  const [copiedValue, setCopiedValue] = useState<string | null>(null)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { mutation } = useBucketInvitationCreate()
  const { isSharingPlan } = useBucketPlan()
  const { mutation: mutationBucketUpdate } = useBucketUpdate()

  let copy = 'Copy Again'
  let create = 'Create and Copy'

  if (isIOS || isSafari) {
    copy = 'Copy Link'
    create = 'Create Link'
  }

  useEffect(() => {
    if (!inviteCache[userRole]) {
      setCopiedValue(null)
    }
  }, [inviteCache, userRole])

  useEffect(() => {
    if (copyState.error) {
      createAlert(copyState.error.message, 'error')
    }
  }, [copyState.error, createAlert])

  const addInviteToCache = (role: BucketUserRole, id: string) => {
    const updatedCache = { ...inviteCache }
    updatedCache[role] = id
    setInviteCache(updatedCache)
  }

  useQuery(BucketInvitationQueryDocument, {
    skip: !bucketId,
    variables: { bucketId: bucketId!, userRole },
    onError: (e) => {
      e.graphQLErrors.forEach((error) => {
        createAlert(error.message, 'error', error)
      })
    },
    onCompleted: (bucketInvitation) => {
      const results = bucketInvitation?.bucketInvitationQuery?.items || []
      const invitation = head(results)
      if (invitation) {
        addInviteToCache(invitation.userRole, invitation.id)
      }
    }
  })

  const handleClickDropdown = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseDropdown = () => {
    setAnchorEl(null)
  }

  const handleCopy = async () => {
    if (!bucketId) {
      return
    }

    if (bucket?.bucketType === BucketType.Mybucket) {
      if (isSharingPlan && !isDefaultTitle) {
        mutationBucketUpdate({
          variables: {
            bucketId: bucket?.id || '',
            data: { bucketType: BucketType.Group }
          }
        })
      } else {
        modalVar('bucketUpgrade')
        return
      }
    }

    // If user pressed the copy button multiple times
    if (copiedValue) {
      copyToClipboard(copiedValue)
      createAlert('Link copied to clipboard', 'success')
      return
    }

    mutation({
      variables: { bucketId, userRole },
      onCompleted: async ({ bucketInvitationCreate }) => {
        cache.updateQuery({ query: BucketInvitationsDocument, variables: { bucketId, nextToken: null } }, (data) => {
          if (!data || !bucketInvitationCreate) return null
          const { bucketInvitations } = data
          const results = data?.bucketInvitations?.items || []
          const combinedItems = _.concat(results, bucketInvitationCreate)

          const sortedItems = [...combinedItems].sort((a, b) => {
            if (!a.createdAt || !b.createdAt) return 0
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          })

          return {
            bucketInvitations: {
              ...bucketInvitations,
              items: sortedItems
            }
          }
        })

        const invitation = head(bucketInvitationCreate)
        let text
        if (invitation) {
          addInviteToCache(invitation.userRole, invitation.id)
          text = `https://${baseDomain}/invite/${invitation.id}`
          setCopiedValue(text)
          // safari browers and ios devices block copy if not a direct input
          const apple = isIOS || isSafari
          if (!apple) {
            copyToClipboard(text)
            createAlert('Link copied to clipboard', 'success')
          }
        }
      },
      onError: (e) => {
        e.graphQLErrors.forEach((error) => {
          createAlert(error.message, 'error', error)
        })
      }
    })
  }

  const handleOption = (role: BucketUserRole) => {
    return () => {
      const cachedCopyValue = inviteCache[role]
      if (cachedCopyValue) {
        const text = `https://${baseDomain}/invite/${cachedCopyValue}`
        setCopiedValue(text)
      } else {
        setCopiedValue(null)
      }
      setAnchorEl(null)
      setUserRole(role)
    }
  }

  return (
    <>
      <ButtonGroup variant="outlined">
        <Button
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClickDropdown}
          size="small"
          sx={{ borderRight: 'none', '&.MuiButton-outlined': { color: theme.palette.customGrey[800], fontWeight: 'normal' } }}
          variant="outlined"
        >
          {startCase(userRole.toLowerCase())} Invite Link
          <ArrowDropDown htmlColor={theme.palette.customGrey[800]} />
        </Button>
        <Button
          startIcon={<FileCopyOutlined />}
          onClick={handleCopy}
          color={copiedValue ? 'success' : 'primary'}
          data-test="copy-invite-link"
          variant="outlined"
          size="small"
          sx={{
            textAlign: 'left',
            backgroundColor: copiedValue ? theme.palette.customSuccess[50] : theme.palette.brand[50]
          }}
        >
          {copiedValue ? copy : create}
        </Button>
      </ButtonGroup>
      <Menu
        id="copy-link-role-menu"
        MenuListProps={{
          'aria-labelledby': 'copy-link-role-menu'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseDropdown}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5
          }
        }}
      >
        {items.map((option) => (
          <MenuItem key={option} onClick={handleOption(option)}>
            {startCase(option.toLowerCase())} Invite Link
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
